import {
  NgModule,
  CUSTOM_ELEMENTS_SCHEMA,
  APP_INITIALIZER,
} from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser/ngx';
import {
  OwlDateTimeModule,
  OwlNativeDateTimeModule,
} from '@danielmoncada/angular-datetime-picker';

// Componentes
import { CalendarComponent } from './components/calendar/calendar.component';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { FabComponent } from './components/fab/fab.component';
import { DatetimePickerComponent } from 'src/app/shared/components/datetime-picker/datetime-picker.component';
import { PopoverMenuComponent } from './components/popover-menu/popover-menu.component';
import { PopoverPaymentComponent } from 'src/app/shared/components/popover-payment/popover-payment.component';
import { PopoverFilterComponent } from 'src/app/shared/components/popover-filter/popover-filter.component';
import { PopoverPartnersComponent } from 'src/app/shared/components/popover-partners/popover-partners.component';
import { PopoverInputComponent } from 'src/app/shared/components/popover-input/popover-input.component';
import { PopoverPromotionsCodeComponent } from 'src/app/shared/components/popover-promotions-code/popover-promotions-code.component';
import { ModalInformationComponent } from './components/modal-information/modal-information.component';

// Firebase
import { FirebaseMessaging } from '@awesome-cordova-plugins/firebase-messaging/ngx';
import { FirebaseAnalytics } from '@awesome-cordova-plugins/firebase-analytics/ngx';
import { FirebaseCrash } from '@awesome-cordova-plugins/firebase-crash/ngx';

// Traductor
import { TranslateModule } from '@ngx-translate/core';

// Pipes
import { LanguagePipe } from './pipes/language/language.pipe';
import { ReplacePipe } from './pipes/replace/replace.pipe';
import { NumberToTimePipe } from './pipes/number-time/number-to-time.pipe';

// Directivas
import { InputIntegerDirective } from './directives/input-integer/input-integer.directive';
import { InputFloatingNumberDirective } from './directives/input-floating-number/input-floating-number.directive';
import { ClickCatcherDirective } from './directives/click-catcher/click-catcher.directive';

// Servicios
import { InjectorService } from './services/injector/inyector.service';
import {
  initLanguageService,
  LanguageService,
} from './services/language/language.service';
import {
  GlobalService,
  initGlobalService,
} from 'src/app/shared/services/global/global.service';
import { HttpClient } from '@angular/common/http';
import { StorageService } from './services/storage/storage-service.service';
import { PaymentsService } from '../payments/services/payments/payments.service';
import { ContentsService } from '../contents/services/contents/contents.service';

// Font Awesome
import {
  FontAwesomeModule,
  FaIconLibrary,
} from '@fortawesome/angular-fontawesome';
import {
  faFilter,
  faChevronRight,
  faClock,
  faFileSignature,
  faHistory,
  faStore,
  faTasks,
  faInfoCircle,
  faCalendarDay,
  faMapMarker,
  faLock,
  faUnlock,
  faEuroSign,
  faChartLine,
  faBiking,
  faBullseye,
  faFireAlt,
  faRoute,
  faSort,
  faPlayCircle,
  faPlay,
  faPause,
  faRetweet,
  faRedoAlt,
  faAngry,
  faTired,
  faMeh,
  faSmile,
  faGrinAlt,
  faHourglassHalf,
  faCalendarWeek,
  faCalendarCheck,
  faClipboardCheck,
  faThList,
  faFlag,
  faTicketAlt,
  faBoxes,
  faMap,
  faCoins,
  faFileInvoice,
  faChild,
  faPen,
  faTachometerAlt,
  faHeartbeat,
  faWeight,
  faMale,
  faPlusCircle,
  faMinusCircle,
  faUser,
  faUsers,
  faUserCog,
  faUserPlus,
  faBell,
  faWifi,
  faBan,
  faShoppingCart,
  faStopwatch,
  faDumbbell,
  faChartArea,
  faSignal,
  faCircle,
  faCheckCircle,
  faBaseballBall,
  faLink,
  faFileDownload,
  faVideo,
  faLaptopHouse,
  faUtensils,
  faSyncAlt,
  faEye,
  faSave,
  faLightbulb,
  faShareAlt,
  faCheckDouble,
  faArrowLeft,
  faBars,
  faEllipsisV,
  faCamera,
  faChartBar,
  faHandHoldingHeart,
  faBaby,
  faSlidersH,
  faLayerGroup,
  faComments,
  faDoorOpen,
  faDoorClosed,
  faEnvelope,
  faCommentDollar,
  faExclamationCircle,
  faEnvelopeOpen,
  faQrcode,
  faAddressCard,
  faTicket,
  faCreditCard,
  faSlash,
  faHome,
  faSignOut,
  faSignIn,
  faIdCard,
  faTimes,
  faBoxesStacked,
  faFilePdf,
  faIdBadge,
  faChevronDown,
  faBurn
} from '@fortawesome/free-solid-svg-icons';
import {
  faClock as farClock,
  faTimesCircle,
  faCircle as farCircle,
  faCircleDown as farCircleDown,
} from '@fortawesome/free-regular-svg-icons';
import { faBluetoothB } from '@fortawesome/free-brands-svg-icons';

@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,
    FabComponent,
    DatetimePickerComponent,
    CalendarComponent,
    PopoverMenuComponent,
    PopoverPaymentComponent,
    PopoverFilterComponent,
    PopoverPartnersComponent,
    PopoverInputComponent,
    PopoverPromotionsCodeComponent,
    ModalInformationComponent,
    LanguagePipe,
    ReplacePipe,
    NumberToTimePipe,
    InputIntegerDirective,
    InputFloatingNumberDirective,
    ClickCatcherDirective
  ],
  imports: [
    TranslateModule.forChild({
      isolate: false,
    }),
    CommonModule,
    FontAwesomeModule,
    FormsModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    NgCircleProgressModule.forRoot({ lazy: false }),
  ],
  exports: [
    // SharedModule se encarga de exportar los modulos IonicModule, CommonModule, FormsModule, ReactiveFormsModule, FontAwesomeModule
    // para que no sea necesario importarlos en todos los modulos
    IonicModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FontAwesomeModule,

    // No se exporta TranslateModule, usamos servicio y pipe propios de traduccion encapsulando TranslateModule
    //TranslateModule,

    HeaderComponent,
    FooterComponent,
    FabComponent,
    DatetimePickerComponent,
    CalendarComponent,
    PopoverMenuComponent,

    LanguagePipe,
    ReplacePipe,
    NumberToTimePipe,

    InputIntegerDirective,
    InputFloatingNumberDirective,
    ClickCatcherDirective
  ],
  providers: [
    FirebaseAnalytics,
    FirebaseMessaging,
    FirebaseCrash,
    InAppBrowser,
    PaymentsService,
    ContentsService,
    {
      provide: APP_INITIALIZER,
      useFactory: initLanguageService,
      deps: [LanguageService],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initGlobalService,
      deps: [GlobalService, HttpClient, StorageService],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => (): void => {},
      deps: [InjectorService],
      multi: true,
    },
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SharedModule {
  constructor(library: FaIconLibrary) {
    library.addIcons(
      faFilter,
      faChevronRight,
      faClock,
      farClock,
      faFileSignature,
      faHistory,
      faStore,
      faTasks,
      faInfoCircle,
      faCalendarDay,
      faMapMarker,
      faLock,
      faUnlock,
      faEuroSign,
      faChartLine,
      faBiking,
      faBullseye,
      faFireAlt,
      faRoute,
      faSort,
      faPlayCircle,
      faPlay,
      faPause,
      faRetweet,
      faRedoAlt,
      faGrinAlt,
      faSmile,
      faMeh,
      faTired,
      faAngry,
      faHourglassHalf,
      faCalendarWeek,
      faCalendarCheck,
      faClipboardCheck,
      faThList,
      faFlag,
      faTicketAlt,
      faBoxes,
      faMap,
      faCoins,
      faFileInvoice,
      faChild,
      faPen,
      faTachometerAlt,
      faHeartbeat,
      faWeight,
      faMale,
      faPlusCircle,
      faMinusCircle,
      faUser,
      faUsers,
      faUserCog,
      faUserPlus,
      faBell,
      faBan,
      faWifi,
      faShoppingCart,
      faStopwatch,
      faDumbbell,
      faChartArea,
      faSignal,
      faCircle,
      faBaseballBall,
      faTimesCircle,
      faCheckCircle,
      farCircle,
      faLink,
      faFileDownload,
      faVideo,
      faLaptopHouse,
      faUtensils,
      faSyncAlt,
      faEye,
      faSave,
      faBluetoothB,
      faLightbulb,
      faShareAlt,
      faCheckDouble,
      faArrowLeft,
      faBars,
      faEllipsisV,
      faCamera,
      faChartBar,
      faHandHoldingHeart,
      faBaby,
      faSlidersH,
      faLayerGroup,
      faComments,
      faDoorOpen,
      faDoorClosed,
      faEnvelope,
      faCommentDollar,
      faExclamationCircle,
      faEnvelopeOpen,
      faQrcode,
      faAddressCard,
      faCreditCard,
      faTicket,
      faSlash,
      faHome,
      faSignOut,
      faSignIn,
      faIdCard,
      faTimes,
      faBoxesStacked,
      faFilePdf,
      farCircleDown,
      faIdBadge,
      faChevronDown,
      faBurn
    );
  }
}
