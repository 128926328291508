import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpResponse, HttpParams } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Platform } from '@ionic/angular';
import * as moment from 'moment';
//SERVICIOS
import { DataHttpService } from 'src/app/shared/services/data-http/data-http.service';
import { GlobalService } from '../../../shared/services/global/global.service';
//MODELOS
import { PendingPayments } from 'src/app/shared/models/payments/pending-payments';
import { ShopPendingPayment } from 'src/app/shared/models/payments/shop-pending-payment';
import { ShopBuyingLine } from 'src/app/shared/models/shop/shop-buying-line';
import { FeePendingPayment } from 'src/app/shared/models/payments/fee-pending-payment';
import { ReservationPendingPayment } from 'src/app/shared/models/payments/reservation-pending-payment';
import { PendingPaymentResourceParam } from 'src/app/shared/models/payments/pending-payment-resource-param';
import { PendingResourcePaymentOperationParam } from 'src/app/shared/models/payments/pending-resource-payment-operation-param';
import { DoPaymentParams } from 'src/app/shared/models/payments/do-payment-params';
import { ResourcePaymentMethods } from 'src/app/shared/models/payments/resource-payment-methods';
import { PendingResourcePaymentMechanisms } from 'src/app/shared/models/payments/pending-resource-payment-mechanisms';
import { BalanceBonusBase } from 'src/app/shared/models/hirings/bonus/balance-bonus';
import { Discount } from 'src/app/shared/models/payments/discount';
import { CourseInscriptionPendingPayment } from 'src/app/shared/models/payments/course-inscription-pending-payment';
import { BonusBase } from 'src/app/shared/models/hirings/bonus/bonus';
import { ReserveFee } from 'src/app/shared/models/fees/reserve-fee';
import { CollectiveZonesFee } from 'src/app/shared/models/fees/collective-zones-fee';
import { OrderOriginEnum } from 'src/app/shared/models/payments/order-origin.enum';
import { PendingResourcePaymentOriginEnum } from 'src/app/shared/models/payments/pending-resource-payment-origin.enum';
import { ModeKioskEnum } from 'src/app/shared/models/others/mode-kiosk.enum';
import { InitPaytefParam } from 'src/app/shared/models/payments/init-paytef-param';
import { PaymentElementTypeEnum } from 'src/app/shared/models/payments/payment-element-type.enum';
import { AppPaymentMethodEnum } from 'src/app/shared/models/payments/app-payment-method.enum';
import { PaymentOperationResultEnum } from 'src/app/shared/models/payments/payment-operation-result.enum';
import { PaytefOperationStatusEnum } from 'src/app/shared/models/payments/paytef-operation-status.enum';
import { MultibonusBase } from 'src/app/shared/models/hirings/bonus/multibonus-base';
import { PaymentMechanism } from 'src/app/shared/models/payments/payment-mechanism';

@Injectable()
export class PaymentsService {
  constructor(
    private dataService: DataHttpService,
    private globalService: GlobalService,
    private platform: Platform
  ) {}

  /**
   * Devuelve los pagos pendientes de un socio
   *
   * @param idMember Identificador de socio
   */
  public getPendingPayments(idMember: number): Observable<PendingPayments> {
    return this.dataService
      .doGet('/socios/' + idMember + '/pagos', 'v1.0')
      .pipe(
        map((response: HttpResponse<any>) => {
          const pendingPayments = new PendingPayments();

          pendingPayments.buyings = response.body.compras.map(
            (shopPendingPayment: any) => {
              return new ShopPendingPayment({
                buyingId: shopPendingPayment.numTicket,
                netAmount: shopPendingPayment.importeNeto,
                pendingNetAmount: shopPendingPayment.importeNetoPendiente,
                grossAmount: shopPendingPayment.importeBruto,
                taxAmount: shopPendingPayment.importeIVA,
                buyingDate: new Date(
                  moment(shopPendingPayment.fechaCompra).format()
                ),
                buyingLines: shopPendingPayment.lineasCompra.map(
                  (buyingLine: any) => {
                    return new ShopBuyingLine({
                      amount: buyingLine.importe,
                      pendingAmount: buyingLine.importePendiente,
                      discountPercentage: buyingLine.descuentoPorcentaje,
                      product: buyingLine.producto,
                      productImage: buyingLine.fotoProducto
                        ? buyingLine.fotoProducto
                        : this.globalService.defaultImage,
                      units: buyingLine.unidades,
                      gsmKey: null,
                    });
                  }
                ),
              });
            }
          );

          pendingPayments.fees = response.body.cuotas.map(
            (feePendingPayment: any) => {
              return new FeePendingPayment({
                id: feePendingPayment.id,
                activityId: feePendingPayment.idActividadContratada,
                startDate: feePendingPayment.fechaDesde,
                endDate: feePendingPayment.fechaHasta,
                name: feePendingPayment.nombre,
                amount: feePendingPayment.importe,
              });
            }
          );

          pendingPayments.zoneReservations = response.body.reservasZonas.map(
            (reservationPendingPayment: any) => {
              return new ReservationPendingPayment({
                id: reservationPendingPayment.id,
                zoneName: reservationPendingPayment.nombreZona,
                amount: reservationPendingPayment.importe,
                start: new Date(
                  moment(reservationPendingPayment.inicio).format()
                ),
                end: new Date(moment(reservationPendingPayment.fin).format()),
                reservationDate: new Date(
                  moment(reservationPendingPayment.fechaReserva).format()
                ),
              });
            }
          );

          pendingPayments.serviceReservations =
            response.body.reservasServicios.map(
              (reservationPendingPayment: any) => {
                return new ReservationPendingPayment({
                  id: reservationPendingPayment.id,
                  zoneName: reservationPendingPayment.nombreZona,
                  amount: reservationPendingPayment.importe,
                  start: new Date(
                    moment(reservationPendingPayment.inicio).format()
                  ),
                  end: new Date(moment(reservationPendingPayment.fin).format()),
                  reservationDate: new Date(
                    moment(reservationPendingPayment.fechaReserva).format()
                  ),
                });
              }
            );

          pendingPayments.courseInscriptions =
            response.body.inscripcionesCursos.map((courseInscription: any) => {
              return new CourseInscriptionPendingPayment({
                idCandidature: courseInscription.idCandidatura,
                nameCourse: courseInscription.nombreCurso,
                numberCourse: courseInscription.numeroCurso,
                dateInscription: new Date(
                  moment(courseInscription.fechaInscripcion).format()
                ),
                datePaymentLimit: new Date(
                  moment(courseInscription.fechaLimitePago).format()
                ),
                amount: courseInscription.importe,
              });
            });

          return pendingPayments;
        })
      );
  }

  /**
   * Realiza un pago pendiente de un socio
   *
   * @param idCenter Identificador de centro
   * @param doPaymentParams Datos del pago
   */
  public doPendingPayment(
    idCenter: number,
    doPaymentParams: DoPaymentParams
  ): Observable<{
    pasarelaURL: string;
    payPalURL: string;
    pedido: string;
    cashDroURL: string;
    idCashDroOperacion: number;
  }> {
    return this.dataService
      .doPost('/centros/' + idCenter + '/pagos', 'v1.0', doPaymentParams)
      .pipe(
        map((response: HttpResponse<any>) => {
          if (response.body) {
            return {
              pasarelaURL: response.body.pasarelaURL,
              payPalURL: response.body.payPalURL,
              pedido: response.body.pedido,
              cashDroURL: response.body.cashDroURL,
              idCashDroOperacion: response.body.idCashDroOperacion,
            };
          } else {
            return {
              pasarelaURL: null,
              payPalURL: null,
              pedido: null,
              cashDroURL: null,
              idCashDroOperacion: null,
            };
          }
        })
      );
  }

  /**
   * Comprueba el estado de un pago
   *
   * @param idCenter Identificador de centro
   * @param order Numero de pedido pasarela/paypal
   * @param idOperation Identificador de operacion cashdro/paytef
   * @param paymentMethod Metodo de pago
   */
  public getOrderState(
    idCenter: number,
    order: string,
    idOperation: number,
    paymentMethod: AppPaymentMethodEnum
  ): Promise<PaymentOperationResultEnum> {
    return new Promise<PaymentOperationResultEnum>((resolve, rejects) => {
      if (paymentMethod === AppPaymentMethodEnum.gateway) {
        this.getGatewayOrderState(idCenter, order).subscribe({
          next: (resp) => {
            if (resp) {
              resolve(PaymentOperationResultEnum.completedSuccesfully);
            }

            resolve(PaymentOperationResultEnum.pending);
          },
          error: (err) => {
            rejects(err);
          },
        });
      } else if (paymentMethod === AppPaymentMethodEnum.payPal) {
        this.getPayPalOrderState(idCenter, order).subscribe({
          next: (resp) => {
            if (resp) {
              resolve(PaymentOperationResultEnum.completedSuccesfully);
            }

            resolve(PaymentOperationResultEnum.pending);
          },
          error: (err) => {
            rejects(err);
          },
        });
      } else if (paymentMethod === AppPaymentMethodEnum.cashDro) {
        this.getCashdroOperationState(idCenter, idOperation).subscribe({
          next: (resp) => {
            if (resp) {
              resolve(PaymentOperationResultEnum.completedSuccesfully);
            }

            resolve(PaymentOperationResultEnum.pending);
          },
          error: (err) => {
            rejects(err);
          },
        });
      } else if (paymentMethod === AppPaymentMethodEnum.payTef) {
        this.getPaytefOperationState(idCenter, idOperation).subscribe({
          next: (resp) => {
            if (resp === PaytefOperationStatusEnum.completedSuccesfully) {
              resolve(PaymentOperationResultEnum.completedSuccesfully);
            } else if (
              resp === PaytefOperationStatusEnum.completedUnsuccesfully
            ) {
              resolve(PaymentOperationResultEnum.completedUnsuccesfully);
            } else if (resp === PaytefOperationStatusEnum.cancelled) {
              resolve(PaymentOperationResultEnum.cancelled);
            } else if (resp === PaytefOperationStatusEnum.error) {
              resolve(PaymentOperationResultEnum.error);
            } else {
              resolve(PaymentOperationResultEnum.pending);
            }
          },
          error: (err) => {
            rejects(err);
          },
        });
      }
    });
  }

  /**
   * Comprueba el estado de un pago por pasarela
   *
   * @param idCenter Identificador de centro
   * @param idOrder Identificador de pedido
   */
  public getGatewayOrderState(
    idCenter: number,
    idOrder: string
  ): Observable<any> {
    return this.dataService
      .doGet('/centros/' + idCenter + '/pedido/' + idOrder, 'v1.0')
      .pipe(
        map((response: HttpResponse<any>) => {
          return response.body;
        })
      );
  }

  /**
   * Comprueba el estado de un pago PayPal
   *
   * @param idCenter Identificador de centro
   * @param idOrder Identificador de pedido
   */
  public getPayPalOrderState(
    idCenter: number,
    idOrder: string
  ): Observable<any> {
    return this.dataService
      .doGet('/centros/' + idCenter + '/pedidopaypal/' + idOrder, 'v1.0')
      .pipe(
        map((response: HttpResponse<any>) => {
          return response.body;
        })
      );
  }

  /**
   * Comprueba el estado de un pago por cashdro
   *
   * @param idCenter Identificador de centro
   * @param idOperation Identificador de operacion
   */
  public getCashdroOperationState(
    idCenter: number,
    idOperation: number
  ): Observable<any> {
    return this.dataService
      .doGet('/centros/' + idCenter + '/cashdro/' + idOperation, 'v1.0')
      .pipe(
        map((response: HttpResponse<any>) => {
          return response.body;
        })
      );
  }

  /**
   * Comprueba el estado de un pago por paytef
   *
   * @param idCenter Identificador de centro
   * @param idOperation Identificador de operacion
   */
  public getPaytefOperationState(
    idCenter: number,
    idOperation: number
  ): Observable<any> {
    return this.dataService
      .doGet(
        '/centros/' + idCenter + '/paytef/operation/' + idOperation + '/status',
        'v1.0'
      )
      .pipe(
        map((response: HttpResponse<any>) => {
          return response.body;
        })
      );
  }

  /**
   * Devuelve las formas de pago del socio o centro
   *
   * @param idCenter Identificador de centro
   * @param idMember Identificador de socio
   * @param pendingPaymentResourceParam Datos recurso pendiente de pago
   */
  public getResourcePaymentMethods(
    idCenter: number,
    pendingPaymentResourceParam: PendingPaymentResourceParam,
    idMember?: number
  ): Observable<ResourcePaymentMethods> {
    if (idMember) {
      return this.getResourcePaymentMethodsMember(
        idMember,
        pendingPaymentResourceParam
      );
    } else {
      return this.getResourcePaymentMethodsCenter(
        idCenter,
        pendingPaymentResourceParam
      );
    }
  }

  /**
   * Devuelve las formas de pago del socio
   *
   * @param idMember Identificador de socio
   * @param pendingPaymentResourceParam Datos recurso pendiente de pago
   */
  public getResourcePaymentMethodsMember(
    idMember: number,
    pendingPaymentResourceParam: PendingPaymentResourceParam
  ): Observable<ResourcePaymentMethods> {
    return this.dataService
      .doPost(
        '/socios/' + idMember + '/formaspagorecurso',
        'v1.0',
        pendingPaymentResourceParam
      )
      .pipe(
        map((response: HttpResponse<any>) => {
          return this.generateResourcePaymentMethodsFromBody(response.body);
        })
      );
  }

  /**
   * Devuelve las formas de pago del centro
   *
   * @param idCenter Identificador de centro
   * @param pendingPaymentResourceParam Datos recurso pendiente de pago
   */
  public getResourcePaymentMethodsCenter(
    idCenter: number,
    pendingPaymentResourceParam: PendingPaymentResourceParam
  ): Observable<ResourcePaymentMethods> {
    return this.dataService
      .doPost(
        '/centros/' + idCenter + '/formaspagorecurso',
        'v1.0',
        pendingPaymentResourceParam
      )
      .pipe(
        map((response: HttpResponse<any>) => {
          return this.generateResourcePaymentMethodsFromBody(response.body);
        })
      );
  }

  /**
   * Devuelve los mecanismos de pago del socio o centro
   *
   * @param idCenter Identificador de centro
   * @param idMember Identificador de socio
   * @param pendingResourcePaymentOperationParam Datos recurso pendiente de pago
   */
  public getPendingResourcePaymentMechanisms(
    idCenter: number,
    pendingResourcePaymentOperationParam: PendingResourcePaymentOperationParam,
    idMember?: number
  ): Observable<PendingResourcePaymentMechanisms> {
    if (idMember) {
      return this.getPendingResourcePaymentMechanismsMember(
        idMember,
        pendingResourcePaymentOperationParam
      );
    } else {
      return this.getPendingResourcePaymentMechanismsCenter(
        idCenter,
        pendingResourcePaymentOperationParam
      );
    }
  }

  /**
   * Devuelve los mecanismos de pago del socio
   *
   * @param idMember Identificador de socio
   * @param pendingPaymentResourceParam Datos recurso pendiente de pago
   */
  public getPendingResourcePaymentMechanismsMember(
    idMember: number,
    pendingPaymentResourceParam: PendingResourcePaymentOperationParam
  ): Observable<PendingResourcePaymentMechanisms> {
    return this.dataService
      .doPost(
        '/socios/' + idMember + '/mecanismospagorecurso',
        'v1.0',
        pendingPaymentResourceParam
      )
      .pipe(
        map((response: HttpResponse<any>) => {
          return this.generatePendingResourcePaymentMechanismsFromBody(response.body);
        })
      );
  }

  /**
   * Devuelve los mecanismos de pago del centro
   *
   * @param idCenter Identificador de centro
   * @param pendingPaymentResourceParam Datos recurso pendiente de pago
   */
  public getPendingResourcePaymentMechanismsCenter(
    idCenter: number,
    pendingPaymentResourceParam: PendingResourcePaymentOperationParam
  ): Observable<PendingResourcePaymentMechanisms> {
    return this.dataService
      .doPost(
        '/centros/' + idCenter + '/mecanismospagorecurso',
        'v1.0',
        pendingPaymentResourceParam
      )
      .pipe(
        map((response: HttpResponse<any>) => {
          return this.generatePendingResourcePaymentMechanismsFromBody(response.body);
        })
      );
  }

  /**
   * Devuelve los descuentos de un socio
   *
   * @param idMember Identificador de socio
   * @param type tienda o zona o servicio
   * @param itemId Identificador de producto de tienda o zona o servicio
   */
  public getMemberDiscount(
    idMember: number,
    type: 'tienda' | 'zona' | 'servicio',
    itemId?: number
  ): Observable<Discount> {
    let params: HttpParams = new HttpParams();

    if (itemId) {
      params = params.append('idElemento', itemId);
    }

    return this.dataService
      .doGet('/socios/' + idMember + '/descuento/' + type, 'v1.0', params)
      .pipe(
        map((response: HttpResponse<any>) => {
          if (response.body) {
            return new Discount({
              name: response.body.nombre,
              type: response.body.tipo,
              amount: response.body.cantidad,
            });
          }
        })
      );
  }

  public openPaymentURL(paymentURL: string): void {
    let platform: OrderOriginEnum = OrderOriginEnum.appWeb;

    if (this.platform.is('cordova')) {
      if (this.platform.is('android')) {
        platform = OrderOriginEnum.appAndroid;
      } else if (this.platform.is('ios')) {
        platform = OrderOriginEnum.appIOS;
      }
    }

    window.location.href = paymentURL + '&plataforma=' + platform;
  }

  /**
   * Inicia un pago por datáfono Paytef
   *
   * @param idCenter Identificador de centro
   * @param initPaytefParam Datos del pago
   */
  public initPaytefPayment(
    idCenter: number,
    initPaytefParam: InitPaytefParam
  ): Observable<HttpResponse<any>> {
    return this.dataService
      .doPost(
        '/centros/' + idCenter + '/paytef/initpaid',
        'v1.0',
        initPaytefParam
      )
      .pipe(
        map((response: HttpResponse<any>) => {
          return response;
        })
      );
  }

  public sendInvoiceToEmail(
    idCenter: number,
    idElement: number,
    typeElement: PaymentElementTypeEnum,
    email: string
  ): Observable<void> {
    const param: any = {
      tipoElementoPedido: typeElement as number,
      idElementoPedido: idElement,
      email,
    };

    return this.dataService.doPost(
      '/centros/' + idCenter + '/facturas/enviaremail',
      'v1.0',
      param
    );
  }

  public getOrderOrigin(): OrderOriginEnum {
    let orderOrigin: OrderOriginEnum = OrderOriginEnum.appWeb;

    if (this.platform.is('cordova') && this.platform.is('android')) {
      orderOrigin = OrderOriginEnum.appAndroid;
    } else if (this.platform.is('cordova') && this.platform.is('ios')) {
      orderOrigin = OrderOriginEnum.appIOS;
    }

    return orderOrigin;
  }

  public getPendingResourcePaymentOrigin(): PendingResourcePaymentOriginEnum {
    let orderOrigin: PendingResourcePaymentOriginEnum = PendingResourcePaymentOriginEnum.myClub;

    if (this.platform.is('cordova') && (this.platform.is('android') || this.platform.is('ios'))) {
      orderOrigin = PendingResourcePaymentOriginEnum.app;
    } else if (this.globalService.kioskMode !== ModeKioskEnum.disabled) {
      orderOrigin = PendingResourcePaymentOriginEnum.kiosk;
    }
    
    return orderOrigin;
  }

  private generateResourcePaymentMethodsFromBody(
    body: any
  ): ResourcePaymentMethods {
    return new ResourcePaymentMethods({
      pendingPayment: body.pagoPendiente,
      visaPayPayment: body.pagoVisaPay,
      gatewayPayment: body.pagoPasarela,
      gatewayPaymentOneClick: body.pagoPasarelaOneClick,
      payPalPayment: body.pagoPayPal,
      cashDroPayment: body.pagoCashDro,
      payTefPayment: body.pagoPayTef,
      bonuses:
        body.bonos && body.bonos.filter((b) => b.sesiones > 0).length > 0
          ? body.bonos
              .filter((b) => b.sesiones > 0)
              .map((bonus: any) => {
                return new BonusBase({
                  id: bonus.id,
                  name: bonus.nombre,
                  sessions: bonus.sesiones,
                });
              })
          : new Array<BonusBase>(),
      fees:
        body.cuotas && body.cuotas.filter((c) => c.sesiones > 0).length > 0
          ? body.cuotas
              .filter((c) => c.sesiones > 0)
              .map((c: any) => {
                return new ReserveFee({
                  id: c.id,
                  name: c.nombre,
                  sessions: c.sesiones,
                  unlimitedSessions: c.sesionesIlimitadas
                });
              })
          : new Array<ReserveFee>(),
      collectiveZonesFees: body.cuotasZonasColectivas
        ? body.cuotasZonasColectivas.map((c: any) => {
            return new CollectiveZonesFee({
              id: c.id,
              name: c.nombre,
            });
          })
        : new Array<CollectiveZonesFee>(),
      balanceBonus: !body.bonoSaldo
        ? null
        : new BalanceBonusBase({
            id: body.bonoSaldo.id,
            name: body.bonoSaldo.nombre,
            balance: body.bonoSaldo.saldo,
          }),
      multibonuses: !body.multibonos
        ? null
        : body.multibonos.map((m: any) => {
            return new MultibonusBase({
              id: m.id,
              name: m.nombre,
              balance: m.saldo,
            });
          }),
    });
  }

  private generatePendingResourcePaymentMechanismsFromBody(
    body: any
  ): PendingResourcePaymentMechanisms {
    return new PendingResourcePaymentMechanisms({
      pendingPayment: body.pagoPendiente,
      paymentMechanisms: body.mecanismosPago
        ? body.mecanismosPago.map((m: any) => {
            return new PaymentMechanism({
              appPaymentMechanism: m.mecanismoPago,
              appPaymentMethod: m.formaPagoApp
            });
          })
        : new Array<PaymentMechanism>(),
      bonuses:
        body.bonos && body.bonos.filter((b) => b.sesiones > 0).length > 0
          ? body.bonos
              .filter((b) => b.sesiones > 0)
              .map((bonus: any) => {
                return new BonusBase({
                  id: bonus.id,
                  name: bonus.nombre,
                  sessions: bonus.sesiones,
                });
              })
          : new Array<BonusBase>(),
      fees:
        body.cuotas && body.cuotas.filter((c) => c.sesiones > 0).length > 0
          ? body.cuotas
              .filter((c) => c.sesiones > 0)
              .map((c: any) => {
                return new ReserveFee({
                  id: c.id,
                  name: c.nombre,
                  sessions: c.sesiones,
                  unlimitedSessions: c.sesionesIlimitadas
                });
              })
          : new Array<ReserveFee>(),
      collectiveZonesFees: body.cuotasZonasColectivas
        ? body.cuotasZonasColectivas.map((c: any) => {
            return new CollectiveZonesFee({
              id: c.id,
              name: c.nombre,
            });
          })
        : new Array<CollectiveZonesFee>(),
      balanceBonus: !body.bonoSaldo
        ? null
        : new BalanceBonusBase({
            id: body.bonoSaldo.id,
            name: body.bonoSaldo.nombre,
            balance: body.bonoSaldo.saldo,
          }),
      multibonuses: !body.multibonos
        ? null
        : body.multibonos.map((m: any) => {
            return new MultibonusBase({
              id: m.id,
              name: m.nombre,
              balance: m.saldo,
            });
          }),
    });
  }
}
